import { useContent } from '@sitecore/common';
import { ComponentRendering } from '@sitecore/types/lib';


export const usePlaceholderComponentContent = <T extends ComponentRendering>(
  placeholderName: string,
  componentName: string,
): T | null => {
  const { placeholders } = useContent();
  const placeholder = placeholders?.[placeholderName];
  if (!placeholder) return null;
  const component = placeholder.find(rendering => rendering.componentName === componentName);
  if (!component) return null;
  return component as T;
};

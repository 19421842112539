import { Session } from 'next-auth';
import useSWR from 'swr';

/**
 * A hook to fetch data from authenticated api's in DC BE. Only when the accessToken is set the call will be done.
 * this prevents multiple calls when de state is still loading
 * @param fetcher The DC BE client API fetch method
 * @param options The options for the API endpoint
 * @param keys Keys on which the data needs to be fetched
 * @param sessionData the session object from DXP/Auth.
 * @returns useSWR response object
 *
 * @example
 * import { getApiLabelsByLabelPricingDynamic } from "@dc-be/client";
 * const {data, isLoading, error} = usePublicDCBE(getApiLabelsByLabelPricingDynamic, {
 *   query: {
 *     startDate: '2024-08-01',
 *     endDate: '2024-08-01',
 *     aggregation: 'hourly',
 *   }
 * })
 */

export default function useAuthenticatedDCBE<T, W>(
  fetcher: (options: T) => Promise<W>,
  options: T,
  keys: string[] = [],
  sessionData: Session | null,
) {
  return useSWR<W>(sessionData?.accessToken ? fetch.name + keys.join() : null, () => fetcher(options));
}
